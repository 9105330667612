import React from 'react';

import Reveal from 'react-reveal/Reveal';
import Slide from 'react-reveal/Slide';

import Divider from '../SVG/Layout/Divider';

import './NotFound.scss';

const NotFound: React.FC = () => {
  return (
    <section className="not-found">
      <Slide top>
        <h2 className="not-found--header">Not Found</h2>
      </Slide>
      <Reveal effect="growDivider">
        <Divider color="#e9e9e9" />
      </Reveal>
    </section>
  );
};

export default NotFound;
