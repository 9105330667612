import React, { Fragment } from 'react';

import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

import NotFound from '../components/NotFound';

import './styles.scss';

const NotFoundPage: React.FC = () => {
  React.useEffect(() => {
    navigate('/');
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Page Not Found</title>
        <meta property="og:description" content={'UI/UX Designer & Engineer'} />
      </Helmet>
      <NotFound />
    </Fragment>
  );
};

export default NotFoundPage;
